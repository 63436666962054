import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Stack, StackProps } from '@mui/material';
// components
import { MotionContainer, varFade } from '../components/animate';
import { DualLogo } from 'src/components/Logo';
import { isMobile } from 'src/utils/isMobile';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[400],
  [theme.breakpoints.up('lg')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));

const ContentStyle = styled((props: StackProps) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 520,
    margin: 'auto',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    position: 'relative',
    paddingBottom: theme.spacing(15),
    
    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
      textAlign: 'left',
      paddingTop: theme.spacing(15),
    },
  })
);

const HeroBGImageStyle = styled(m.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export const HeroOverlayStyle = styled(m.div)({
  zIndex: 10,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: 'rgba(255,255,255,.7)'
});

const HeroImgStyle = styled(m.div)(({ theme }) => ({
  zIndex: 10,
  width: '90%',
  margin: 'auto',
  position: 'relative',
  paddingTop: 100,

  [theme.breakpoints.up('lg')]: {
    right: '5%',
    width: 'auto',
    position: 'absolute',
    paddingTop: 0,
  },  
  [theme.breakpoints.up('xl')]: {
    left: '35%',
    width: 'auto',
    position: 'absolute',
    paddingTop: 0,
  },  
}));

// ----------------------------------------------------------------------

export default function HomeHero() {
  const mobile = isMobile();
  return (
    <MotionContainer>
      <RootStyle>
        <HeroBGImageStyle alt="overlay" src="/assets/kdv/bg0.jpeg" variants={varFade().in} />
        <HeroOverlayStyle />

        <HeroImgStyle>
          <DualLogo size={mobile ? 120: 200} />
        </HeroImgStyle>

        <Container>
          <ContentStyle>
            <m.div variants={varFade().inRight}>
              <Typography variant="h1" sx={{ color: 'common.dark' }}>
                Welkom bij<br />
                <Typography component="span" variant="h1" sx={{ color: 'primary.main' }}>
                  De Nanny
                </Typography>
              </Typography>
            </m.div>

            <m.div variants={varFade().inRight}>
              <Typography variant="h4" sx={{ color: 'common.dark' }}>
                Kinderdagverblijf

                <Typography component='span' variant="h4" sx={{ color: 'primary.main', ml: 1, mr: 1 }}>
                  & <br />
                </Typography>

                <Typography component='span' variant="h4" sx={{ color: 'common.dark' }}>
                  Buitenschoolse Opvang
                </Typography>

              </Typography>
            </m.div>

          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { lg: '100vh' } }} />
    </MotionContainer>
  );
}
