import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Divider, Box } from '@mui/material';
// hooks
import Image from '../components/Image';
import { MotionContainer, varFade } from '../components/animate';
import useResponsive from 'src/hooks/useResponsive';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    textAlign: 'center',
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        textAlign: 'left',
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(10),
    },
}));

// ----------------------------------------------------------------------
function renderSection(image: string, title: string, descriptions: string[], rtl: boolean, isMobile: boolean) {
    let textAlign = rtl ? 'end' : 'start';
    let sxPadding: any = rtl ? { pr: 6 } : { pl: 6 }

    if (isMobile) {
        textAlign = 'center';
        sxPadding = {}
    }

    return <m.div key={title} variants={varFade().inLeft}><Grid container sx={rtl ? { flexDirection: 'row-reverse' } : {}}>
        <Grid sx={{}} item xs={12} md={5}>

            <Image style={{
                borderRadius: isMobile ? 4 : 0,
                height: isMobile ? 400 : 'unset'
            }} src={image} />

        </Grid>
        <Grid item xs={12} md={7} sx={{ ...sxPadding, }}>
            <Typography color={'#34495e'} sx={{ textAlign: textAlign, mt: isMobile ? 6 : 15 }} variant="h2">{title}</Typography>
            {
                descriptions.map(description => <Typography color={'#34495e'} sx={{ textAlign: textAlign, mt: 3, pb: 4 }} variant="body1">{description}</Typography>)
            }
        </Grid>
    </Grid>

        {
            isMobile && <Divider sx={{ my: { xs: 8, lg: 10 } }} />
        }
        {
            !isMobile && <Divider sx={{ mb: 0 }} />
        }
    </m.div>
}

export default function SectionMeetTheTeam() {
    const isDesktop = useResponsive('up', 'md');

    return (
        <RootStyle>
            <Container component={MotionContainer}>
                <m.div variants={varFade().inUp}>
                    <Typography component="p" variant="overline" sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}>
                        Dream Team
                    </Typography>

                    <Typography variant="h2" sx={{ textAlign: 'center', color: '#34495e' }}>
                        Meet the Team
                    </Typography>
                    <Typography component="p" variant="overline" sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}>
                        Onze Professionals
                    </Typography>
                    <Image
                        alt="team"
                        src="/assets/team/groep.JPG"
                        sx={{ borderRadius: 2, mt: 4, maxWidth: 800, margin: 'auto' }}
                    />

                </m.div>
                <Divider sx={{ my: { xs: 8, lg: 15 } }} />
                {
                    [
                        {
                            image: '/assets/team/Linda.JPG',
                            title: 'Linda Stelling',
                            description: ["Hoi hoi ik ben Linda en trotse mamma van een zoontje Jex", "Ik ben ook de trotse eigenaresse van de Nanny Sinds 01 mei 2011 met veel plezier werk ik nog twee dagen op de groep en Voor de rest doe ik veel achter de schermen.", "Hopelijk tot snel bij de Nanny"]
                        },
                        {
                            image: '/assets/team/Gerrianne.JPG',
                            title: 'Gerri-Anne',
                            description: ["Hallo ik ben Gerri-Anne en werkzaam sinds September 2022 bij de Nanny. Ik ben een vlotte wat oudere creatieve dame die veel plezier haalt uit heet werken met kindjes.", "Hopelijk tot snel bij de Nanny en gaan we samen veel creatieve knutsel werkjes maken."]
                        },
                        {
                            image: '/assets/team/Myrte.JPG',
                            title: 'Myrte Hurkmans',
                            description: ["Hoi ik ben Myrte en ben sinds 2019 werkzaam bij de Nanny.  Samen met mij Collega’s bieden wij een fijne tweede thuis voor de kinderen.", "Hopelijk tot ziens bij de Nanny."]
                        },
                        {
                            image: '/assets/team/Nadine.JPG',
                            title: 'Nadine Couwenberg',
                            description: ["Hey mijn naam is Nadine ik ben sinds Januari 2020 begonnen bij de Nanny. Ik ga elke dag weer met plezier naar mijn werk.", "Wie weet zie ik je snel bij de Nanny"]
                        }
                    ].map((props, index) => renderSection(props.image, props.title, props.description, index % 2 === 0, !isDesktop))
                }

            </Container>
        </RootStyle >
    );
}