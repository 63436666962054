import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';

// components
import { varFade } from '../components/animate';
import TextSection from 'src/components/TextSection';
import { Slide } from 'react-slideshow-image'
import { useTeam } from 'src/hooks/useTeam';
import Iconify from 'src/components/Iconify';


const RootStyle = styled('div')(({ theme }) => ({

  paddingBottom: theme.spacing(0),

  [theme.breakpoints.up('xs')]: {
    paddingTop: theme.spacing(8)
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(15)
  },
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 300,
  overflow: 'hidden',
  borderRadius: 0,
  userSelect: 'none'
}));

const ProfileImage = styled('img')(({ theme }) => ({
  maxHeight: 300,
  width: '100%',
  maxWidth: 'unset',
  height: 300,
  objectFit: 'cover',
  userSelect: 'none'
}));

// ----------------------------------------------------------------------
export default function SectionTeam() {
  const { profiles } = useTeam();

  return (<RootStyle>
    <TextSection
      upperText='Dream team'
      title='Het Team'
      content={["Het team bestaat uit verantwoordelijke, ervaren en gepassioneerde collega's. We zijn erg op elkaar ingespeeld en kunnen hierdoor iedere situatie professioneel oplossen!"]}
      children={<Box sx={{ position: 'relative' }}>
        <m.div variants={varFade().inDown}>
          <a href="#/Team" style={{
            color: 'primary.main',
            textDecoration: 'none'
          }}>
            <Button variant='outlined'>
              <Iconify sx={{ mr: 2, fontSize: '2em' }} icon="eva:eye-outline" />
              <Typography variant="subtitle1">
                Ontmoet het Team
              </Typography>
            </Button>
          </a>
        </m.div>
        {profiles && profiles.length > 0 &&
          <Slide
            indicators={false}
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 400,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },

            ]}
            easing='ease'>
            {profiles.map((member) => (
              <Box
                key={member.name}
                component={m.div}
                variants={varFade().in}
                sx={{
                  px: 1.5, pt: { lg: 5, sm: 0 }, pb: 10, mb: {
                    sm: 0,
                    lg: 10
                  }
                }}
              >
                <MemberCard member={member} />
              </Box>
            ))}
          </Slide>
        }
      </Box>}
    />
  </RootStyle>
  );
}

// ----------------------------------------------------------------------

type MemberCardProps = {
  member: {
    name: string;
    role: string;
    avatar: string;
  };
};

function MemberCard({ member }: MemberCardProps) {
  const { name, role, avatar } = member;
  return (
    <Card key={name} sx={{ p: 0, background: '#eeeeee57' }}>
      <Typography variant="body1" sx={{ my: 4, fontSize: '1em' }}>
        {name}
      </Typography>
      <Typography variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
        {role}
      </Typography>
      <ImageWrapper>
        <ProfileImage draggable={false} alt={name} src={avatar} sx={{ borderRadius: 0 }} />
      </ImageWrapper>
    </Card>
  );
}
