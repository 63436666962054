// @mui
import { Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import TextSection from 'src/components/TextSection';
import HeroSmall from 'src/sections/HeroSmall';
import { getHandout } from 'src/utils/handouts';
import Page from '../components/Page';

import {
  SectionContact
} from '../sections';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  zIndex: 20,
  paddingTop: 100,
  backgroundColor: theme.palette.background.default,
}));
// ----------------------------------------------------------------------

export default function GGDPage() {
  return (
    <Page title="De Nanny">
      <HeroSmall subtitleProps={{
        subtitle1: 'GGD Inspectie'
      }} imageUrl='/assets/kdv/group.jpeg' />


      <ContentStyle>
        <TextSection
          title='Inspecties'
          upperText='Voor ieders veiligheid'
          content={['Wij nemen de inspecties zeer serieus, dit is namelijk ook onze eigen controle of wij nog volgens alle gestelde richtlijnen te werk gaan, hierdoor zijn zowel u als wij er zeker van dat dit een veilige en gezonde omgeving voor uw kind is.'
            , 'Bekijk hieronder onze inspectie rapporten']}
        >
        </TextSection>
        {
          [
            {
              description: 'GGD Inspectie',
              title: 'Kinderdagverblijf',
              urls: [{
                formName: 'inspectierapport 2023 (mei)',
                url: getHandout('Inspectierapport KDV 2023 MEI')
              },
              {
                formName: 'inspectierapport 2023 (februari)',
                url: getHandout('Inspectierapport KDV 2023 FEB')
              },{
                formName: 'inspectierapport 2022 (oktober)',
                url: getHandout('221017 Incid Kdv De Nanny (def)')
              },
              {
                formName: 'inspectierapport 2022 (april)',
                url: getHandout('220407 NO KDV De Nanny (def)')
              }]
            },
            {
              description: 'GGD Inspectie',
              title: 'Buitenschoolse opvang',
              urls: [{
                formName: 'inspectierapport 2023',
                url: getHandout('Inspectierapport BSO 2023')
              },{
                formName: 'inspectierapport 2022',
                url: getHandout('GGD BSO 2022')
              }, {
                formName: 'inspectierapport 2021',
                url: getHandout('BSO 2021')
              }]
            }
          ].map((item, index) => (
            <Container key={index} component={MotionViewport}>
              <Box sx={{
                backgroundColor: index % 2 === 0 ? '#F4F6F8' : '#fff',
                pb: 10,
                pt: 10,
                pl: { xs: 2, lg: 5 },
                alignItems: 'center'
              }}>

                <m.div variants={varFade().inUp}>
                  <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
                    {item.description}
                  </Typography>
                </m.div>
                <m.div variants={varFade().inDown}>
                  <Typography variant="h2" sx={{ mb: 3 }}>
                    {item.title}
                  </Typography>
                </m.div>
                <div>
                  {
                    item.urls.map((i, iindex) => (
                      <m.div key={iindex} variants={varFade().inDown}>
                        <Typography
                          sx={{
                            color: 'text.primary',
                          }}
                        >
                          <a target='_blank' style={{ color: 'rgb(85, 26, 139)' }} href={i.url}>
                            <Typography component="h1" variant="h4" sx={{ m: 0, textTransform: 'none', textAlign: 'start' }}>
                              {i.formName}
                            </Typography>
                          </a>
                        </Typography>
                      </m.div>
                    ))
                  }
                </div>


              </Box>
            </Container>
          ))
        }

        <SectionContact gray subtitle='Voor vragen over de prijs, neem gerust contact op. Wij helpen u graag verder' />
      </ContentStyle>
    </Page>
  );
}
