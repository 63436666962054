import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Image from '../components/Image';
import Iconify from '../components/Iconify';
import { varFade, MotionViewport } from '../components/animate';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SectionPricing() {
  return (
    <RootStyle>
      <Container component={MotionViewport}>
        <Box sx={{ mb: 10, textAlign: 'center' }}>

          <m.div>
            <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
              Overzicht kosten
            </Typography>
          </m.div>
          <m.div>
            <Typography variant="h2" sx={{ mb: 3 }}>
              De juiste prijs voor iedere situatie
            </Typography>
          </m.div>
          <m.div>
            <Typography
              sx={{
                color: 'text.secondary',
              }}
            >
              Bekijk hier onder onze opties
            </Typography>
          </m.div>
        </Box>

        <Grid container spacing={5}>
          {[
            {
              license: "Dagdeel 1",
              options: [
                "€51,15 voor dagdeel",
                "€9,30 per uur",
                "Van 07:00 tot 12.30",
                "Vaste tijden"
              ],
            },
            {
              license: "Dagdeel 2",
              options: [
                "€55,80 voor dagdeel",
                "€9,30 per uur",
                "Van 12:30 tot 18.30",
                "Vaste tijden"
              ],
            },
            {
              license: "Flex",
              options: [
                "Prijs afhankelijk van duur",
                "€9,90 per uur",
                "Tussen 07:00 en 18.30",
                "Tijd zelf te bepalen"
              ],
            }
          ].map((plan, index) => (
            <Grid key={plan.license} item xs={12} md={4}>
              <m.div
                variants={varFade({durationIn: index * .8}).inUp}
              >
                <PlanCard plan={plan} />
              </m.div>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: {xs: 10, lg: 20}, mb: 10, textAlign: 'center' }}>

          <m.div variants={varFade().inUp}>
            <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
              Extra Informatie
            </Typography>
          </m.div>
          <m.div variants={varFade().inDown}>
            <Typography variant="h2" sx={{ mb: 3 }}>
              Tijden & Vakantie
            </Typography>
          </m.div>
          <m.div variants={varFade().inDown}>
            <Typography
              sx={{
                color: 'text.secondary',
              }}
            >
              Wij zijn geopend bij de Buitenschoolseopvang op maandag,dinsdag en donderdag. (woensdag en vrijdag ook mogelijk)<br />
              Gesloten zijn we op nationale feestdagen, de vrijdag na Hemelvaart en maandag en dinsdag met carnaval. <br /><br />
              Op 5 December sluiten wij om 16:00 en 24 en 31 december sluiten we de deuren om 15.00 uur. <br />
              In de zomervakantie sluiten wij altijd twee weken meestal de laaste twee weken van de zomervakantie van de Bassischolen.
            </Typography>
          </m.div>

          <m.div variants={varFade({durationIn: 2}).inUp}>
            <Image
              alt="our office 2"
              src="/assets/kdv/bg2.jpeg"
              ratio="16/9"
              sx={{ borderRadius: 2, mt: 8 }}
            />
          </m.div>
        </Box>

      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

type PlanCardProps = {
  plan: {
    license: string;
    options: string[];
  };
};

function PlanCard({ plan }: PlanCardProps) {
  const { license, options } = plan;

  const standard = license === 'Standard';
  const plus = license === 'Standard Plus';

  return (
    <Card
      sx={{
        p: 5,
        boxShadow: 0,
        ...(plus && {
          boxShadow: (theme) => theme.customShadows.z24,
        }),
      }}
    >
      <Stack spacing={5}>
        <Typography variant="h4">{license}</Typography>



        <Stack spacing={2.5}>

          {options.map((option, optionIndex) => {
            const disabledLine =
              (standard && optionIndex === 1) ||
              (standard && optionIndex === 2) ||
              (standard && optionIndex === 3) ||
              (plus && optionIndex === 3);

            return (
              <Stack
                spacing={1.5}
                direction="row"
                alignItems="center"
                sx={{
                  ...(disabledLine && { color: 'text.disabled' }),
                }}
                key={option}
              >
                <Iconify
                  icon={'eva:checkmark-fill'}
                  sx={{
                    width: 20,
                    height: 20,
                    color: 'primary.main',
                    ...(disabledLine && { color: 'text.disabled' }),
                  }}
                />
                <Typography variant="body2">{option}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Card>
  );
}
