// @mui
import { styled } from '@mui/material/styles';
import HeroSmall from 'src/sections/HeroSmall';
import Page from '../components/Page';

import {
  SectionContact,
  SectionPricing
} from '../sections';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  zIndex: 20,
  backgroundColor: theme.palette.background.default,
}));
// ----------------------------------------------------------------------

export default function PricingPage() {
  return (
    <Page title="De Nanny">
      <HeroSmall subtitleProps={{ subtitle1: 'Kosten' }} imageUrl='/assets/kdv/bg3.jpeg' />

      <ContentStyle>
        <SectionPricing />
        <SectionContact subtitle='Voor vragen over de prijs, neem gerust contact op. Wij helpen u graag verder' />
      </ContentStyle>
    </Page>
  );
}
