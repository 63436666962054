import { Suspense, lazy, ElementType, Children } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import MainLayout from 'src/layouts/main';
import PricingPage from 'src/pages/Kosten';
import GalleryPage from 'src/pages/Gallery';
import GGDPage from 'src/pages/GGD';
import InschrijvenPage from 'src/pages/Inschrijven';
import TeamPage from 'src/pages/Team';
import VacaturesPage from 'src/pages/VacaturesPage';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: <Home />
        },
        {
          path: 'Kosten',
          element: <PricingPage />
        },
        {
          path: 'Team',
          element: <TeamPage />
        },
        {
          path: 'Gallerij',
          element: <GalleryPage />
        },
        {
          path: 'GGD',
          element: <GGDPage />
        },
        {
          path: 'Inschrijven',
          element: <InschrijvenPage />
        },
        {
          path: 'Vacatures',
          element: <VacaturesPage />
        }
      ]
    }
  ]);
}

// Dashboard
const Home = Loadable(lazy(() => import('../pages/Home')));
