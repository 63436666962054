import { useState, useEffect } from 'react';

class TeamStore {
  public static profiles: any[] = [];
}

export function useTeam() {
  const [fetching, setFetching] = useState(true);
  const [profiles, setProfiles] = useState<any[]>();

  useEffect(() => {
    if (TeamStore.profiles.length === 0) {
      const _profiles = [
        {
          name: "Linda",
          url: "Linda"
        },
        {
          name: "Gerri-Anne",
          url: "Gerrianne"
        },
        {
          name: "Myrte",
          url: "Myrte"
        },
        {
          name: "Nadine",
          url: "Nadine"
        },
      ].map(i => ({
        name: i.name,
        avatar: `/assets/team/${i.url}.JPG`
      }));

      TeamStore.profiles = _profiles;
      setProfiles(_profiles);
      setFetching(false);
    }
    else {
      setFetching(false);
      setProfiles(TeamStore.profiles);
    }
  }, []);

  return { fetching, profiles };
}