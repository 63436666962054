// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-outline'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Team',
    icon: <Iconify icon={'carbon:user-profile'} {...ICON_SIZE} />,
    path: '/Team',
  },
  {
    title: 'Kosten',
    icon: <Iconify icon={'eva:book-open-outline'} {...ICON_SIZE} />,
    path: '/Kosten',
  },
  {
    title: 'Inschrijven',
    icon: <Iconify icon={'eva:edit-outline'} {...ICON_SIZE} />,
    path: '/Inschrijven'
  },
  {
    title: 'GGD Inspectie',
    icon: <Iconify icon={'eva:checkmark-outline'} {...ICON_SIZE} />,
    path: '/GGD'
  },
  {
    title: 'Galerij',
    icon: <Iconify icon={'eva:image-outline'} {...ICON_SIZE} />,
    path: '/Gallerij',
  },
  {
    title: 'Vacatures',
    icon: <Iconify icon={'eva:image-outline'} {...ICON_SIZE} />,
    path: '/Vacatures',
  }
];

export default menuConfig;
