// @mui
import { Container, Box, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { m } from "framer-motion";
import { MotionViewport, varFade } from "src/components/animate";
import TextSection from "src/components/TextSection";
import HeroSmall from "src/sections/HeroSmall";
import { getHandout } from "src/utils/handouts";
import Page from "../components/Page";

import { SectionContact } from "../sections";

const ContentStyle = styled("div")(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  backgroundColor: theme.palette.background.default,
  zIndex: 10,
}));

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up("lg")]: {
    textAlign: "left",
    paddingTop: theme.spacing(14),
  },
}));
// ----------------------------------------------------------------------

export default function InschrijvenPage() {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  //HeroSmall
  return (
    <Page title="De Nanny | Inschrijven">
      <HeroSmall
        subtitleProps={{
          subtitle1: "Inschrijven",
        }}
        imageUrl="/assets/kdv/bg5.jpeg"
      />
      <ContentStyle>
        <RootStyle>
          <TextSection
            title="Formulieren & Informatie"
            content={[
              "Download hier onder onze inschrijfformulieren & informatieboekjes",
            ]}
          />
          {[
            // {
            //   description: 'Inschrijven & Informatie',
            //   title: 'Kinderdagverblijf',
            //   urls: [{
            //     formName: 'inschrijfformulier',
            //     url: getHandout('AANMELDINGSFORMULIER KDV 2023')
            //   },
            //   {
            //     formName: 'informatieboekje',
            //     url: getHandout('KDV - Informatieboekje de Nanny')
            //   }]
            // },
            {
              title: "Inschrijven",
              description: "Kinderdagverblijf & BSO",
              urls: [
                {
                  formName: "Inschrijven",
                  url: "https://inschrijving.novict.nl/ilJLpNrPbqc",
                },
              ],
            },
            {
              description: "Dagverblijf en buitenschoolse opvang",
              title: "Informatie",
              urls: [
                {
                  formName: "Informatieboekje KDV ",
                  url: getHandout("KDV - Informatieboekje de Nanny"),
                },
                {
                  formName: "Informatieboekje BSO",
                  url: getHandout(
                    "BSO - Informatieboekje de Nanny (drukwerk bestand)"
                  ),
                },
              ],
            },            
            {
              description: "Voor het gebruik van medicijnen",
              title: "Overeenkomst Gebruik Geneesmiddelen",
              urls: [
                {
                  formName: "Download Overeenkomst",
                  url: getHandout("Overeenkomst geneesmiddelen"),
                },
              ],
            },
          ].map((item, index) => (
            <Container key={index} component={MotionViewport}>
              <Box
                sx={{
                  backgroundColor: index % 2 === 0 ? "#F4F6F8" : "#fff",
                  pb: 10,
                  pt: 10,
                  pl: { xs: 2, lg: 5 },
                  alignItems: "center",
                }}
              >
                <m.div variants={varFade().inUp}>
                  <Typography
                    component="div"
                    variant="overline"
                    sx={{ mb: 2, color: "text.disabled" }}
                  >
                    {item.description}
                  </Typography>
                </m.div>
                <m.div variants={varFade().inDown}>
                  <Typography variant="h2" sx={{ mb: 3 }}>
                    {item.title}
                  </Typography>
                </m.div>
                <div>
                  {item.urls.map((i, iindex) => (
                    <m.div key={iindex} variants={varFade().inDown}>
                      <Typography
                        sx={{
                          color: "text.primary",
                        }}
                      >
                        <a target="_blank" href={i.url}>
                          <Typography
                            component="h1"
                            variant="h4"
                            sx={{
                              m: 0,
                              textTransform: "none",
                              textAlign: "start",
                              color: "rgb(85, 26, 139)",
                            }}
                          >
                            {i.formName}
                          </Typography>
                        </a>
                      </Typography>
                    </m.div>
                  ))}
                </div>
              </Box>
            </Container>
          ))}

          <Box sx={{ mt: 10, mb: 0, textAlign: "center", width: "100%" }}>
            <SectionContact gray subtitle="Neem gerust contact op!" />
          </Box>
        </RootStyle>
      </ContentStyle>
    </Page>
  );
}
