import { Link as RouterLink } from 'react-router-dom';
// @mui
import { BoxProps, styled } from '@mui/material';
import Image from './Image';
import { isMobile } from 'src/utils/isMobile';
import { MotionContainer, varFade } from './animate';
import { m } from 'framer-motion';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo_url = "/assets/kdv/logo-bso.png";
  const logo = <Image sx={{ height: 50, maxHeight: 50, ...sx }} src={logo_url} />

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}


const RootStyle = styled(MotionContainer)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export function DualLogo(props: { size?: number }) {
  const mob = isMobile()
  const { size } = props;
  const logo_bso = <img style={{ height: size ?? 50, maxHeight: size ?? 50 }} src="/assets/kdv/logo-bso.png" />
  const logo_kdv = <img style={{ marginLeft: mob ? 0 : 16, marginTop: mob ? 16 : 0, marginBottom: mob ? 16 : 0, height: size ?? 50, maxHeight: size ?? 50 }} src="/assets/kdv/logo-kdv.png" />

  const effect1 = varFade().inLeft;
  const effect2 = varFade().inRight;
  effect2.animate.transition.duration = 1;

  return <RootStyle sx={{
    flexDirection: mob ? 'column' : 'row'
  }}>
    <m.div variants={effect1}>
      <RouterLink to="/">{logo_bso}</RouterLink>
    </m.div>
    <m.div variants={effect2}>
      <RouterLink to="/">{logo_kdv}</RouterLink>
    </m.div>
  </RootStyle>
}

export function DualLogoHeader(props: { size?: number }) {
  const { size } = props;
  const logo_bso = <img style={{ height: size ?? 50, maxHeight: size ?? 50 }} src="/assets/kdv/logo-bso.png" />
  const logo_kdv = <img style={{ marginLeft: 16, height: size ?? 50, maxHeight: size ?? 50 }} src="/assets/kdv/logo-kdv.png" />

  const effect1 = varFade().inLeft;
  const effect2 = varFade().inRight;
  effect2.animate.transition.duration = 1;

  return <RootStyle sx={{
    flexDirection: 'row'
  }}>
    <m.div variants={effect1}>
      <RouterLink to="/">{logo_bso}</RouterLink>
    </m.div>
    <m.div variants={effect2}>
      <RouterLink to="/">{logo_kdv}</RouterLink>
    </m.div>
  </RootStyle>
}
