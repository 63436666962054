// @mui
import { styled } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, Stack } from '@mui/material';
import SocialsButton from '../../components/SocialsButton';
import { DualLogo } from 'src/components/Logo';
import { isMobile } from 'src/utils/isMobile';
import { getHandout } from 'src/utils/handouts';
// ----------------------------------------------------------------------

/*

    {
              description: 'GGD Inspectie',
              title: 'Kinderdagverblijf',
              urls: [{
                formName: 'inspectierapport oktober 2022',
                url: getHandout('221017 Incid Kdv De Nanny (def)')
              }, {
                formName: 'inspectierapport april 2022',
                url: getHandout('220407 NO KDV De Nanny (def)')
              },
              {
                formName: 'inspectierapport 2021',
                url: getHandout('KDV 2021')
              }]
            },
            {
              description: 'GGD Inspectie',
              title: 'Buitenschoolse opvang',
              urls: [{
                formName: 'inspectierapport 2022',
                url: getHandout('220125 BSO De Nanny (def)')
              }, {
                formName: 'inspectierapport 2021',
                url: getHandout('BSO 2021')
              }]
            }

*/
const LINKS = [
  {
    headline: 'Formulieren',
    children: [
      { name: 'Inschrijven', href: 'https://inschrijving.novict.nl/ilJLpNrPbqc' },
      { name: 'Informatieboekje KDV', href: getHandout('KDV - Informatieboekje de Nanny') },
      { name: 'Informatieboekje BSO', href: getHandout('BSO - Informatieboekje de Nanny (drukwerk bestand)') },
      { name: 'Overeenkomst Gebruik Geneesmiddelen', href: getHandout('Overeenkomst geneesmiddelen') },
    ],
  },
  {
    headline: 'InspectieRapport',
    children: [
      { name: 'KDV 2022', href: getHandout('221017 Incid Kdv De Nanny (def)') },
      { name: 'BSO 2022', href: getHandout('220125 BSO De Nanny (def)') },
      { name: 'KDV 2021', href: getHandout('KDV 2021') },
      { name: 'BSO 2021', href: getHandout('BSO 2021') }
    ],
  },
  {
    headline: 'Links',
    children: [
      { name: 'Home', href: '/' },
      { name: 'Team', href: '/#/Team' },
      { name: 'Kosten', href: '/#/Kosten' },
      { name: 'Gallerij', href: '/#/Gallerij' },
      { name: 'Inschrijven', href: '/#/Inschrijven' },
      { name: 'GGD Inspectie', href: '/#/GGD' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const mobile = isMobile();

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: mobile ? 5 : 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={2}>
            {
              !mobile && <DualLogo size={50} />
            }
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'center' }}
              sx={{ mt: mobile ? 0 : 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={9}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list, index) => (
                <Stack key={index} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link, ii) => (
                    <a key={ii} style={{ textDecoration: 'none', color: '#637381' }} href={link.href}>
                      <Typography component="p" variant="overline" sx={{ m: 0, color: 'text.disabled', textTransform: 'none' }}>
                        {link.name}
                      </Typography>
                    </a>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © 2021. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
