import { m } from 'framer-motion';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
import Image from '../components/Image';
import { MotionViewport, varFade } from '../components/animate';
import { isMobile } from 'src/utils/isMobile';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(14),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function SectionWhy() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  const imageSection = (<Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={6}>
        <m.div variants={varFade().inUp}>
          <Image
            alt="our office 1"
            src="/assets/kdv/girl-happy-1.jpeg"
            ratio={isDesktop ? "3/4" : '1/1'}
            sx={{
              borderRadius: 1,
              boxShadow: shadow,
            }}
          />
        </m.div>
      </Grid>
      <Grid item xs={6}>
        <m.div variants={varFade().inUp}>
          <Image
            alt="our office 2"
            src="/assets/kdv/girl-curious-1.jpeg"
            ratio={isDesktop ? "3/4" : '1/1'}
            sx={{ borderRadius: 1 }}
          />
        </m.div>
      </Grid>
    </Grid>
  </Grid>
  );

  const textSection = <Grid item xs={12} md={6} lg={5}>
    <m.div variants={varFade().inRight}>

      <m.div variants={varFade().inRight}>
        <Typography
          variant='h3'
          sx={{ mb: {xs: 3, sm: 1} }}
        >
          Vol Passie
        </Typography>
      </m.div>

      <Typography
        sx={{
          color: 'text.secondary'
        }}
      >
        Kinderdagverblijf De Nanny is ontstaan vanuit onze passie voor kinderen. Na jarenlang werkzaam geweest te zijn met kinderen vonden wij het tijd worden voor een àndere manier van kinderopvang!
      </Typography>
    </m.div>

    <m.div variants={varFade().inRight}>

      <m.div variants={varFade().inRight}>
        <Typography
          variant='h3'
          sx={{ 
            mb: {xs: 3, sm: 1},
            mt: {xs: 10, sm: 3}
          }}
        >
          Pedagogisch Verantwoord
        </Typography>
      </m.div>

      <Typography
        sx={{
          color: 'text.secondary',
          mb: 3
        }}
      >
        Een optimaal flexibele opvang gecombineerd met
        een verantwoord pedagogisch beleid is waar we oor
        staan en wat onze voortdurende aandacht heeft.
      </Typography>
    </m.div>

  </Grid>;

  return (
    <RootStyle>
      <Container component={MotionViewport}>
        <m.div variants={varFade().inDown}>
          <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
            Passie & Liefde
          </Typography>
        </m.div>

        <m.div variants={varFade().inUp}>
          <Typography variant="h2" sx={{textAlign: isMobile() ? 'center' : 'left', mb: {xs: 12, md: 4, sm: 3} }}>
            Waarom De Nanny
          </Typography>
        </m.div>
        <Grid container spacing={3}>


          {
            isDesktop
              ? <>
                {imageSection}
                {textSection}
              </>
              : <>
                {textSection}
              </>
          }
        </Grid>

        <Grid container spacing={3} sx={{textAlign: 'start', pt: {lg: 6}}}>
         
         <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 }, 
        mb: {xs: 6, md: 1}
        }}>
     <m.div variants={varFade().inRight}>
 
       <m.div variants={varFade().inRight}>
         <Typography
           variant='h3'
           sx={{ 
            mb: {xs: 3, sm: 1},
            mt: {xs: 7, sm: 3},
            textAlign: { xs: 'center', md: 'end'}
          }}
         >
           Flexibel
         </Typography>
       </m.div>
 
       <Typography
         sx={{
           color: 'text.secondary',
           textAlign: isDesktop ? 'end' : 'center'
         }}
       >
        Een àndere manier van opvang, waarbij de ouders zelf bepalen wanneer en hoeveel uren ze afnemen. Daarbij willen we zoveel mogelijk tegemoetkomen aan de wensen van deze ouders. Er gelden wel een paar regels aan deze flexibele opvang.
       </Typography>
     </m.div>
   </Grid>
 
      <Grid item xs={12} md={6} lg={5} sx={{ pl: { md: 12 }}}>
            <m.div variants={varFade().inUp}>
              <Image
                alt="our office 2"
                src="/assets/kdv/bg5.jpeg"
                ratio="16/9"
                sx={{ borderRadius: 1 }}
              />
            </m.div>
      </Grid>   

      {!isDesktop && imageSection}
            
         </Grid>
      </Container>
    </RootStyle>
  );
}