// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextSection from 'src/components/TextSection';
import HeroSmall from 'src/sections/HeroSmall';
import Page from '../components/Page';

import {
  SectionContact
} from '../sections';


const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  zIndex: 10
}));

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(14),
  },
}));
// ----------------------------------------------------------------------

export default function VacaturesPage() {
  //HeroSmall
  return (
    <Page title="De Nanny | Vacatures">
      <HeroSmall
        subtitleProps={{
          subtitle1: 'Vacatures'
        }}
        imageUrl='/assets/kdv/bg5.jpeg'
      />
      <ContentStyle>
        <RootStyle>
          <TextSection
            title='Werken bij het leukste team?'
            content={['Bekijk hieronder onze vacatures!']}
          />

          <img src='/assets/kdv/vacature.jpg' />

          <Box sx={{ mt: 10, mb: 0, textAlign: 'center', width: '100%' }}>
            <SectionContact gray subtitle='Neem gerust contact op!' />
          </Box>

        </RootStyle>
      </ContentStyle>
    </Page >
  );
}
