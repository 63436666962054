import { useState, useEffect } from 'react';

class GalleryStore {
    public static profiles: any[] = [];
}

export function useGallery() {
    const [fetching, setFetching] = useState(true);
    const [gallery, setGallery] = useState<{
        img: string,
        rows: number | undefined,
        cols: number | undefined
    }[]>([]);

    useEffect(() => {
        if (GalleryStore.profiles.length === 0) {
            const profs = [
                "baby-puzzle-1.jpeg",
                "bg-home-1.jpeg",
                "boy-happy-1.jpeg",
                "girl-curious-1.jpeg",
                "girl-happy-1.jpeg",
                "girl-happy-2.jpeg",
                "group.jpeg",
                "inspectie.jpeg",
                "kid-bike-1.jpeg",
                "kid-curious-1.jpeg",
                "kid-happy-3.jpeg",
                "kid-kiss-1.jpeg",
                "kid-why.jpeg",
                "kids-group-happy.jpeg",
                "kids-jumping.jpeg",
                "voy-pose-1.jpeg"
            ].map((img, index) => ({
                img: "/assets/kdv/" + img,
                rows: calcRatio(index),
                cols: 1
            }));

            GalleryStore.profiles = profs.reverse();
            setGallery(profs);
            setFetching(false);

        } else {
            setFetching(false);
            setGallery(GalleryStore.profiles);
        }
    });

    return { fetching, gallery };
}

const calcRatio = (index: number) => index % 2 === 0 ? 2 : undefined;